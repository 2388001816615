import './App.css';
import Main from "./Components/Main";
import React from "react";



function App() {
    return (
        <div className="wrapper">
            <Main/>
        </div>
    );
}

export default App;
