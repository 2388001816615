import React, {useEffect} from "react";
import PinField from "react-pin-field"
import '../css/pincode.scss';
import '../css/logout.scss';
import axios from "axios";
import {Button} from "react-bootstrap";

const Login= (props) => {

    let pin = React.createRef();

    useEffect(() => {
        resetPin();
    }, );

    function resetPin(){
        pin.current.forEach(i => (i.value = ""));
        pin.current[0].focus();
    }

    const onLogin = (value) => {
        axios.post(
            (process.env.REACT_APP_API_URL + '/user/login'), {password : value})
            .then(res => {
                resetPin();
                if (res.status === 200) {
                    //store jwt token
                    localStorage.setItem('token', res.data);
                    props.loginAction();
                }else return Promise.reject(res);
            })
            .catch(err => {
                window.location.reload();
                resetPin();
            })
    }

    return(
        <div>
            <PinField ref={pin}
            className={"pin-field"}
            length={6}
            autoFocus
            inputMode="numeric"
            validate="0123456789"
            format={key => key}
            onResolveKey={() => {}}
            onRejectKey={() => {}}
            onChange={() => {}}
            onComplete={onLogin}
            style={{}}
            />
        </div>
    );
}

const Logout= (props) => {
    function onLogOut() {
        localStorage.removeItem("token")
        props.loginAction();
    }
    return <Button className="logout" onClick={onLogOut}>Bye</Button>

}

export {
    Login, Logout
}
