import React, {useEffect, useState} from "react";
import '../../css/eddie.scss';
import Eddie_Brain from "./Eddie_Brain";
import axios from "axios";

const Eddie = (props) => {
    const aquarium = props.environment;

    const eddie = {width:100, height:100};

    const [position, setPosition] = useState({top: 0,left: 0});
    const [rotation, setRotation] = useState(0);
    const [flip, setFlip] = useState('scaleY(1)');

    function calculateBounds(aquariumUpdated){
        return {
            width : aquariumUpdated.bounds.x - eddie.width,
            height : aquariumUpdated.bounds.y - eddie.height/2};
    }

    const BOUNDS = calculateBounds(aquarium)

    const [text, setT] = useState("")
    const [time, setTime] = useState(new Date());

    function randomPos(){
       return [Math.random() * BOUNDS.width, Math.random() * BOUNDS.height]
    }

    useEffect(()=>{
        brain.introduce();
    }, [])

    const moveRandom = () => {
        const pos = randomPos()
        moveEddie(pos[0], pos[1])
    };

    const moveEddie = (x, y) => {
        y-=20//offset image

        const dx = x - position.left;
        const dy = y - position.top;

        setPosition({
            top: y,
            left: x,
        });

        setRotation((Math.atan2(dy, dx) * 180) / Math.PI);
        setFlip(dx < 0 ? 'scaleY(-1)' : 'scaleY(1)');
    }

    const brain = new Eddie_Brain(aquarium, props.setChat, props.setAlert,  props.addItem, props.getItems, moveRandom, moveEddie, position);

    const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
    const useRandomInterval = (callback, minDelay, maxDelay) => {
        const timeoutId = React.useRef(null);
        const savedCallback = React.useRef(callback);
        React.useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);
        React.useEffect(() => {
            let isEnabled =
                typeof minDelay === 'number' && typeof maxDelay === 'number';
            if (isEnabled) {
                const handleTick = () => {
                    const nextTickAt = random(minDelay, maxDelay);
                    timeoutId.current = window.setTimeout(() => {
                        savedCallback.current();
                        handleTick();
                    }, nextTickAt);
                };
                handleTick();
            }
            return () => window.clearTimeout(timeoutId.current);
        }, [minDelay, maxDelay]);
        const cancel = React.useCallback(function () {
            window.clearTimeout(timeoutId.current);
        }, []);
        return cancel;
    };

    const maxGPTPrompts = 20
    const [promptsMade, setPromptsMade] = useState(0)
    const delaySmall = [500, 1500]
    const delayMedium = [1200, 23000]
    const delayLarge = [35000, 60000]

    useRandomInterval(()=>{brain.moveRandom()}, ...delaySmall)

    useRandomInterval(()=>{
        setPromptsMade(promptsMade+1)
        if(!(promptsMade>=maxGPTPrompts)){
            brain.spawnItem()
        }else{
            props.setAlert("")
            props.setChat("Eddie prompted GPT a lot. Refresh the page to get him talking again")
        }
    }, ...delayLarge)

    useRandomInterval(()=>{
        setPromptsMade(promptsMade+0.5)
        if(!(promptsMade>=maxGPTPrompts)){
            brain.speak()
        }else{
            props.setAlert("")
            props.setChat("Eddie prompted GPT a lot. Refresh the page to get him talking again")
        }
    }, ...delayMedium)


    return (<>
                <img src={text}/>
                {text}
                    <div
                        className="eddie"
                        style={{
                            zIndex : 5,
                            top: `${position.top}px`,
                            left: `${position.left}px`,
                            transform: `rotate(${rotation}deg) ${flip}`
                        }}
                    >
                            <img src="/Eddie/eddie.png" alt="Eddie the Fish" />
                    </div>
            </>
    );
};


export default Eddie