import React, {createRef, useEffect, useState} from "react";
import '../../css/aquarium.scss';
import Eddie from "./Eddie";
import {Button} from "react-bootstrap";
import axios from "axios";


const startState = {
    bounds:{x:0,y:0},
    items: [{name:"rock", width:35, x:0, y:100, image:"rock.png", isStatic:true, id:0},
        {name:"rock", width:25, x:50, y:100, image:"rock.png", isStatic:true, id:1}],

    item_count : 2,
}

const Aquarium = () => {
    const aquaRef = createRef()
    const [text, setText] = useState("")
    const [alertText, setAlertText] = useState("")
    const [env, setEnv] = useState(startState)

    function onScreenLoad(){
        const rect= aquaRef.current.getBoundingClientRect();
        if(!rect) setEnv({...env, bounds: {x : window.innerWidth, y : window.innerHeight}}) //issue ...
        setEnv({...env, bounds: {x : rect.width, y : rect.height}})
    }

    useEffect(() => {
        onScreenLoad()
    }, []);

    function getItems() {
        return env.items;
    }
    function addItem(item) { //{name:"rock", x:5, y:0, image:""}
        setEnv({...env, items : [...env.items, item], item_count : (env.item_count+1)})
    }

    function removeItem(id){
        setEnv(
            {...env, items : env.items.filter(a => a.id !=id), item_count : (env.item_count-1)}
        )
    }


    const showPictures = () => {
        return (
            getItems().map(({image,width,x,y, isStatic, id}) =>
                (<div>
                    {isStatic?
                    <img className={"item-static"} style={{top:`${y}%`, left:`${x}%`, width:`${width}%`}} src={image} alt={"wallpaper"}/>
                    :
                    <img className={"item"} onMouseDown={() =>removeItem(id)} style={{top:`${y}%`, left:`${x}%`, width:width, backgroundColor:"green"}} src={image} alt={"wallpaper"}/>}

                </div>)
            )
        )
    }


    return (
        <>

            <div className="chat">
                <div className="note">
                    Eddie:
                </div>

                <div className="text">
                    {alertText} {text}
                </div>
            </div>

            <div className="aquarium-container">
                <div className="aquarium" ref={aquaRef} >
                    <Eddie environment={env} setChat={setText} setAlert={setAlertText} addItem={addItem} getItems={getItems} />
                    <div className="flex-container">
                        <div className="block"></div>
                        <div className="flex-container2">

                            <div className="block2">

                            </div>
                            <div className="item-spawnable">
                                {showPictures()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );




};


export default Aquarium