import React, {useEffect, useState, useRef} from "react";
import axiosApiInstance from "../../Setup/AxiosInterceptors";
import '../../css/gallery.css';

const Gallery= () => {
    const [images, setImages] = useState([])
    const date = new Date()
    const dates = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


    const loadImages = () => {
        const items = 3

        axiosApiInstance.post(
            process.env.REACT_APP_API_URL + '/main/cloudinary', {items : items, day : dates[date.getDay()]})
            .then(res => {
                setImages(res.data)
            }).catch(alert)
    }

    useEffect(() => {
        loadImages()
    }, []);

    let renderedImages = images.map(image =><div className="imageHolder"><img className="image" src={image} alt="missing"/></div>)


    return(
        <>
            <h1>{dates[date.getDay()]} memories</h1>
            <div className="container">
                {renderedImages}
            </div>
        </>
    );
}


export default Gallery
