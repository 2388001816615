import React, {useEffect, useState} from "react";
import '../css/counter.scss';

const Counter = ({startDate}) => {

    function dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    const calculateTimeLeft = () => {
        const now = new Date();
        return dateDiffInDays(startDate, now)
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return(
        <div>

            <div className="counter">
                <div className="counter-value">{timeLeft} <h1>Days</h1> </div>
            </div>
        </div>
    );
}

export default Counter