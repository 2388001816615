import aquarium from "./Aquarium";
import axiosApiInstance from '../../Setup/AxiosInterceptors';


class Eddie_Brain {
    emotions = {
        energy : 5,
        happy : 5,
        confident : 5,
        evil : 5,
        playfulness : 8,
    }

    currentAction = "nothing"

    constructor(aquarium, setChat, setAlert, addItem, getItems, moveRandom, move, position) {
        this.aquarium = aquarium
        this.setChat = setChat
        this.addItem = addItem
        this.getItems = getItems
        this.moveRandom = moveRandom
        this.move = move
        this.setAlert = setAlert
        this.position = position
    }


    base = "You narrate as Eddie the silly mischievous fish's mind. Dont introduce yourself"
    constraints = "(use maximum 50 words and dont use complex words)"
    smallConstraints = "(use maximum 30 words and dont use complex words"

    behaviours = {
        action: this.base + "Do something crazy unexpected" + this.constraints,
        philosophise : this.base + "Philosophise about life outside of your aquarium and humanity" + this.smallConstraints,
        joke : "you are a fish swimming in an aquarium. tell me a joke. Only the joke please.",
        compliment : this.base + "Compliment Luna the girl looking into your aquarium." + this.constraints,
        t1 : this.base + "Play with your friend Herman the Hermit crab" + this.smallConstraints,
        t2 : this.base + "Do something very mischievous and surprising" + this.smallConstraints,

    }

    randomBehaviour = () => {
        let keys = Object.keys(this.behaviours);
        return this.behaviours[keys[ Math.floor(keys.length * Math.random())]];
    };

    introduce() {
        axiosApiInstance.post(
            process.env.REACT_APP_API_URL + '/brain/gpt', {prompt : "You are Eddie the fish and are greeting Luna who is looking in your aquarium. Explain that you sometimes bring objects. Dont use more thn 100 words."})
            .then(res => {
                this.setChat(res.data)
            })
    }

    speak(){
        axiosApiInstance.post(
            process.env.REACT_APP_API_URL + '/brain/gpt', {prompt : this.randomBehaviour()})
            .then(res => {
                this.setChat(res.data)
                this.setAlert(" ")
            })
    }

    spawnItem(){
        axiosApiInstance.post(
            process.env.REACT_APP_API_URL + '/brain/gptCrazy', {prompt : "Name a random object or person or animal. Only answer with that one word."})
            .then(res => {
                let objectName = res.data

                axiosApiInstance.post(
                    process.env.REACT_APP_API_URL + '/brain/image', {prompt : objectName})
                    .then(res => {
                        const xpos = 100* Math.random()
                        const ypos = 100 * Math.random()
                        const imageURL = res.data

                        axiosApiInstance.post(
                            process.env.REACT_APP_API_URL + '/brain/gpt', {prompt : this.base + "You just spawned a " + objectName + ". Do something unexpected with this or explain why you brought it in the aquarium" + this.smallConstraints})
                            .then(res => {
                                const punctuation = /[\.,?!]/g;
                                objectName = objectName.replace(punctuation, "")
                                this.setAlert("(Adds a " + objectName + ")")
                                this.setChat( res.data)
                                this.addItem({name:objectName, image: imageURL, x:xpos, y:ypos, width:75, isStatic:false, id:this.aquarium.item_count + 1})
                            })
                    })
            })
    }
}

export default Eddie_Brain
