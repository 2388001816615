import React, {useEffect, useRef} from "react";
import '../css/protected.scss';
import '../css/main.scss'
import {Logout} from "./Login";
import Counter from "./Counter";
import Aquarium from "./Eddie/Aquarium";
import Gallery from "./Gallery/Gallery"
import {CSSTransition} from "react-transition-group";
import Lottie, {useLottie} from 'lottie-react';
import animationData from "../lotties/lottieHeartAnim.json"
import Aqarium from "./Eddie/Aquarium"

const Protected= (props) => {
    const name = props.username;
    const startDate = new Date("2023-04-29");
    const time = new Date().getHours()
    const message = time<4?"Good Night":time<12?"Good Morning":(time<18?"Good Afternoon":(time<22?"Good Evening":"Good Night"))

    const [ visible, setVisible ] = React.useState(false);
    const [ heartVisible, showHeart ] = React.useState(true);
    const [ eddieButton, showEddieButton ] = React.useState(false);
    const [ eddie, showEddie ] = React.useState(false);

    const options = {
        animationData: animationData,
        loop: true
    };

    useEffect(() => {
        setTimeout(() => {
            setVisible(true)
        }, 5000);

        setTimeout(() => {
            showHeart(false)
        }, 4500);

        setTimeout(() => {
            showEddieButton(true)
        }, 10000);

    }, [])

    const { Heart} = useLottie(options);




    return(
        <div className="content">
            {!visible && !eddie &&
                (<>
                    <div className="popup">
                        {message}<br/>{name}
                    </div>
                        <CSSTransition in={heartVisible} timeout={300} classNames="alert" unmountOnExit appear>
                        <div className="lottieContainer">
                            <Lottie className="lottieHeart" animationData={animationData} loop={true} width={100}/>
                        </div>
                        </CSSTransition>
                </>
                )
            }

            <CSSTransition in={visible && !eddie} timeout={2000} classNames="fade" unmountOnExit>
                <div>
                    <Logout loginAction={props.loginAction}/>

                    <Counter startDate={startDate}/>
                    <Gallery/>
                    {eddieButton &&
                        <button className="EddieButton" onClick={()=>{
                            showEddie(true)
                            setVisible(false)
                        }}>Eddie</button>                    }
                </div>
            </CSSTransition>


            <CSSTransition in={!visible && eddie} timeout={300} classNames="alert" unmountOnExit appear>
                <div>
                    <Logout loginAction={props.loginAction}/>
                    <Aqarium/>
                </div>
            </CSSTransition>

        </div>
    );

/*
    return(
        <div className="content">
        <Aqarium/>
        </div>
    )*/

}

export default Protected