import React, {useEffect, useState} from "react";
import '../css/main.scss';

import Protected from "./Protected";
import {Login} from "./Login";
import axiosApiInstance from "../Setup/AxiosInterceptors";
import {CSSTransition} from "react-transition-group";

import Register from "./Register";

const Main= () => {

    const [auth, setAuth] = useState(false);
    const [name, setName] = useState("Sunshine");

    useEffect(() => {
        checkAuthentification();
    }, []);

    function checkAuthentification(){
        axiosApiInstance.get('/main/protected').then((res) => {
            const user = res.data
            setName(user.name)
            setAuth(true)
        }).catch(e=>{
            console.log(e)
            setAuth(false)
        });
    }

    function authenticated(){
        return (
            <>
                <Protected username={name} loginAction={checkAuthentification}/>
            </>
        );

    }
    function nonAuthenticated(){
        return (
            <>
                <Login loginAction={checkAuthentification}/>
            </>
        );
    }


    return(
        <div className="main">
            <CSSTransition in={!auth} timeout={300} classNames="alert" unmountOnExit appear>
                <p>{nonAuthenticated()}</p>
            </CSSTransition>

            <CSSTransition in={auth} timeout={2000} classNames="fade" unmountOnExit>
                <p>{authenticated()}</p>
            </CSSTransition>
        </div>
    );
}

export default Main